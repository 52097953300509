import Highway from '@dogstudio/highway';
import Swiper from 'swiper/bundle';
// import Swiper and modules styles
import 'swiper/css/bundle';

export default class HomeRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        
    }
    onLeave() {

    }
    onEnterCompleted() {
        const projects = document.querySelectorAll('.project-card');

        projects.forEach((project) => {

            let swiper = new Swiper(project.querySelector('.swiper'), {
                loop: true,
            
                // If we need pagination
                pagination: {
                el: project.querySelector('.swiper-pagination'),
                dynamicBullets: true,
                },
            
                // Navigation arrows
                navigation: {
                nextEl: project.querySelector('.button-next'),
                prevEl: project.querySelector('.button-prev'),
                }
            });
        });
    }
    onLeaveCompleted() {

    }
}
